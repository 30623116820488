main {
    overflow-x: hidden;
}
.p-1 {
    padding: 1%;
}

.pages-menu .w-100:hover,
a .w-8:hover {
    background: #c1c1c1;
}

.h-85 {
    height: 80px;
}
.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.border {
    border: 1px solid #a9a9a9 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

a {
    text-decoration: none;
}

h4 {
    font-size: 13px !important;
}

.pages-menu .w-100 span {
    font-size: 30px !important;
}

.tab-contents {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 11px;
    /* column-count:5;
    column-gap: 10px; */
    text-align: center;
    padding: 0% 12px 12px 12px;
}

.tools {
    /* width: 780px; */
    background: #f8f8f8 !important;
    height: 30px;
}

a:hover,
a:focus {
    text-decoration: none !important;
}

.title {
    /*border-bottom: 3px groove;*/
    padding-bottom: 10px;
}

.text-underline {
    text-decoration: underline;
}
.headwidth {
    width: 33.3%;
}
.btn-sm {
    padding: 0.15rem 0.5rem !important;
    font-size: 0.75rem !important;
}

#accordion .btn-warning,
.btn-warning {
    color: #000;
    background-color: #f7971c;
    border-color: #f7971c;
}

.btn-warning:hover,
.btn-warning:focus {
    color: #000;
    background-color: #f7971c !important;
    border-color: #f7971c !important;
    box-shadow: none !important;
}

/*calculator design */

.calc {
    box-shadow: none !important;
    border: 2px solid black;
}

.calc__display {
    font-size: 1.5rem !important;
    padding: 0.5rem 2rem 0.5rem !important;
    /* border: 1px solid black; */
    border-bottom: 2px solid black;
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important;
}

.calc__btns-container button {
    border: 1px solid black;
}

.calc__btn {
    padding: 0.4rem !important;
    background-color: #f1f3f4 !important;
}

.btn--colored {
    background-color: black !important;
    color: white !important;
}

.btn--equal {
    background-color: #f7971c !important;
    color: white !important;
}

.accordion-height::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

ol li,
ul li,
p {
    text-align: justify;
}

/* background of the scrollbar except button or resizer */

.accordion-height::-webkit-scrollbar-track {
    background-color: #fff;
}

.accordion-height::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
}

/* scrollbar itself */

.accordion-height::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
}

.accordion-height::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */

.accordion-height::-webkit-scrollbar-button {
    display: none;
}

.btn-info {
    background-color: black !important;
    border-color: black !important;
}

.setting-footer {
    position: relative;
    bottom: 0;
    /* top: 30px; */
}

#notepad-accordion .container iframe #header {
    display: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: white !important;
    background-color: #f7971c !important;
}

.nav-tabs .nav-link {
    background-color: black;
    color: white !important;
}

.nav-tabs .nav-link:hover {
    border-color: #f7971c !important;
    color: white !important;
}

.tab-content {
    border: 2px solid #f7971c;
}

.output {
    height: max-content;
    /* border: 3px groove;
    margin-top: 7%;
    padding: 3.5% 5%; */
    text-align: left;
    box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 16px;
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0) !important;
}

.text-gray {
    color: #33393c !important;
}

.text-orange {
    color: #f7971c !important;
}

#contact-us-accordion .table td,
#contact-us-accordion .table th {
    border-top: none !important;
}

.bg-white {
    background-color: white;
}

.bg-warning,
#accordion .card-header {
    background-color: #f7971c !important;
}

.top-50 {
    padding-top: 50px;
}

.footer {
    display: flex;
    justify-content: space-between;
    grid-gap: 8px;
    padding: 0.5% 1% !important;
    background: rgb(246, 159, 54, 0.5) !important;
}

.pages-menu .w-100 {
    padding: 12.5% 0%;
}

.toolbar-menu .w-100 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.toolbar-menu .w-100 h4 {
    font-size: 16px !important;
}

.toolbar-menu:hover,
.toolbar-menu.active {
    color: #8b0000 !important;
}

.page-content a {
    color: #f7971c !important;
}

.page-content p img {
    max-width: 100% !important;
}

.page-content a:hover {
    color: #d87c14 !important;
}

.page-content {
    display: grid;
    grid-template-rows: 60px 1fr;
}

.tab-contents {
    position: relative !important;
}

/* Timer calc css */

.stepper {
    position: relative;
    width: 100%;
    padding: 0.5rem 1.5rem;
}

.stepper .btn {
    font-size: 1.5rem;
}

.stepper-btn-add {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.stepper-btn-substract {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.stepper input {
    border: none;
    border-radius: 0;
    font-size: 6 * 1.5rem;
    text-align: center;
    transition: all 1s ease-in;
}

.stepper input::-webkit-outer-spin-button,
.stepper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* timercss end */

.h-auto {
    height: auto !important;
}

.mobile-header-left,
.mobile-header-center,
.mobile-header-right,
.mobile-footer,
.mobile_break {
    display: none;
}

.header {
    height: 85px !important;
}
.page-content a.video-button {
    color: white !important;
}
@media (orientation: landscape) {
    .tab-contents {
        text-align: center;
        /* padding: 0% 0.5%; */
    }
    .header img {
        width: auto;
        /* height: 65px; */
    }
}

@media (orientation: portrait) {
    .tab-contents {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 7.5px;
        text-align: center;
        padding: 0% 2%;
    }
}

@media (max-width: 320px) {
    .header {
        height: 60px !important;
    }
    .pages-menu .w-100 {
        margin-bottom: 0.3rem !important;
        padding: 9% 0% !important;
    }
    .pages-menu .w-100 span {
        font-size: 24px !important;
    }
    .pages-menu .w-100 h4 {
        /* font-size: 9px !important;*/
        margin-bottom: 0px !important;
    }
    .toolbar-menu .w-100 {
        display: grid !important;
        text-align: center;
    }
    .toolbar-menu .w-100 h4 {
        padding-left: 0px !important;
    }
    .toolbar-menu .w-100 img {
        margin: 0 auto;
    }
    .header img {
        width: auto;
        height: 50px;
    }
    .top-85 {
        margin-top: 60px !important;
        margin-bottom: 140px !important;
    }
    .break,
    .desktop-header,
    .desktop-footer,
    .desktop_break {
        display: none;
    }
    .mobile-header-center,
    .mobile-header-right,
    .mobile-header-left,
    .mobile-footer,
    .mobile_break {
        display: block;
    }
    .page-content p img {
        height: 100% !important;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .react-international-phone-input-container {
        width: 100% !important;
    }
    .break,
    .desktop-header,
    .desktop-footer,
    .desktop_break {
        display: none;
    }
    .mobile-header-center,
    .mobile-header-right,
    .mobile-header-left,
    .mobile-footer,
    .mobile_break {
        display: block;
    }
    .top-85 {
        /* margin-top: 60px !important; */
        margin-bottom: 140px !important;
    }

    .header {
        height: 55px !important;
    }
    .header img {
        width: auto;
        /* height: 50px; */
    }
    .pages-menu .w-100 {
        padding: 15.2% 0% !important;
    }
    .pages-menu .w-100 span {
        font-size: 24px !important;
    }
    .pages-menu .w-100 h4 {
        /*font-size: 9px !important;*/
        margin-bottom: 0px !important;
    }
    .toolbar-menu .w-100 {
        display: grid !important;
        text-align: center;
    }
    .toolbar-menu .w-100 h4 {
        padding-left: 0px !important;
    }
    .tab-contents {
        padding: 0% 0.5% 1% 0.5% !important;
        grid-gap: 9px !important;
    }
    .toolbar-menu .w-100 img {
        margin: 0 auto;
    }
    .copyright-left,
    .copyright-right,
    .copyright-center {
        text-align: center !important;
    }

    .page-content p img.video-logo {
        height: 100px !important;
    }
    .page-content p img.icon {
        height: 20px !important;
    }
    .page-content p img {
        height: 100% !important;
    }

    .stepper {
        padding: 0rem !important;
    }

    .ql-container {
        min-width: auto !important;
    }
    .mobile-footer p {
        font-size: 12px;
    }
    .checkbox-column {
        column-count: 1 !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .toolbar-menu .w-100 img {
        margin: 0 auto;
    }
    .pages-menu .w-100 {
        padding: 32.2% 0% !important;
    }
    .pages-menu .w-100 span {
        font-size: 28px !important;
    }
    /*    .pages-menu .w-100 h4 {
        font-size: 11px !important
    }*/
    .toolbar-menu .w-100 {
        display: grid !important;
        text-align: center;
    }
    .toolbar-menu .w-100 h4 {
        padding-left: 0px !important;
    }
    .tab-contents {
        padding: 0% 0.5% 1% 0.5% !important;
        grid-gap: 10px;
        overflow-y: auto;
    }
}

.stopwatch__display {
    display: flex;
    justify-content: center;
}

.stopwatch__display span {
    width: 200px;
    height: 200px;
    line-height: 200px;
    border-radius: 50%;
    font-size: 30px;
    color: black;
    text-align: center;
    background: #f7971c;
}

.copyright-left {
    text-align: left;
}

.copyright-right {
    text-align: right;
}

.copyright-center {
    text-align: center;
}

.embeddedContent iframe {
    width: 100% !important;
}

.desktop-header table tr td {
    font-size: 11px;
    padding: 5px !important;
}

.desktop-header table b.text-warning {
    color: #f7971c !important;
}

.text-warning {
    color: #f7971c !important;
}

.table-borderless > tr > td,
.table-borderless > tr > th,
.table-borderless > tr > td,
.table-borderless > tr > th,
.table-borderless > tr > td,
.table-borderless > tr > th {
    border: none !important;
}

.desktop-header table .material-icons {
    font-size: 15px !important;
}

/* .left-ads,.right-ads{
    position: absolute;
    width:150px;
    border: 1px solid black;

} */

.position-left {
    left: 150px !important;
}

.position-right {
    right: 150px !important;
}

.main-content {
    width: 100%;
}

.top-85 {
    margin-top: 90px;
    margin-bottom: 105px;
}

.modal.show {
    /* padding-right: 10px; */
    display: block;
}

.bg-yellow {
    background: #f7971c;
}

.icons {
    width: 24px !important;
    height: 24px !important;
}

/* div[data-oembed-url] {
    resize: vertical;
    overflow: hidden;
    max-height: 380px;
} */

div[data-oembed-url] div {
    padding-bottom: 86% !important;
}

.ads_div {
    padding-bottom: 90px;
}

.desktop-header .right a:first-child {
    margin-right: 5px !important;
}

legend {
    font-size: 1rem !important;
    font-weight: bold !important;
}

fieldset {
    margin: 0.5rem 0rem;
}

.w-auto {
    width: auto;
}

.back {
    position: fixed;
    left: 12px;
}

.errorMessage {
    background-color: #dc3545;
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
}

.errorMessage p {
    margin-bottom: 0px !important;
}

.divider {
    border: 0px;
    border-top: 2px solid black;
}

.top-100 {
    margin-top: 60px;
}

.sticky-top {
    position: fixed !important;
    top: 85px !important;
    width: 100%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:after {
    width: 0px;
    height: 0px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #f7971c;
    content: ' ';
    position: relative;
    top: 41px;
    right: 25%;
}

.back {
    background-color: #f7971c !important;
    color: black !important;
    border-color: #f7971c !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #f7971c !important;
}
.shadow {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.share .dropdown-toggle::after {
    display: none !important;
}

.ql-container {
    height: 300px;
}

/* PDf Editor css */
#pdfeditor .btn:focus {
    box-shadow: none !important;
}
#pdfeditor iframe {
    border: 2px solid gray !important;
}
/* #pdfeditor .toolbar{
    position: fixed;
    top: inherit;
    left: inherit;
    right: inherit;
    z-index: 1030;
} */

/* Adr Notepad */
.adr-notepad .ql-container {
    height: 400px;
}
.card .card-header {
    padding: 0% 2.5% !important;
}

.card .card-header .btn-warning:focus {
    box-shadow: none !important;
    background-color: inherit !important;
    border-color: none !important;
}
@media (max-width: 767px) {
    .qrbtns {
        display: inline-flex;
        flex-direction: column;
    }
    .qrbtns button {
        margin-top: 5px;
    }
    #apps #accordion .card .card-body .col-md-6:nth-child(2) {
        margin-top: 0.5rem !important;
    }
    .sticky-top {
        position: fixed;
        top: 55px !important;
        width: 100%;
    }
    .top-85 {
        margin-top: 60px !important;
    }
}
#qrcode .tab-content {
    border-top: 1px solid #f7971c !important;
}

.ql-formats {
    margin-right: 8px !important;
}
.ql-snow .ql-formats:after {
    display: none !important;
}
.ql-snow .ql-formats {
    display: block !important;
}

.ql-editor ol,
.ql-editor ul,
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0.3em !important;
}
.ql-editor li:not(.ql-direction-rtl)::before {
    margin-right: 0.7em !important;
}

@media print {
    .pagebreak {
        page-break-after: always;
    } /* page-break-after works, as well */

    .title {
        text-align: center;
    }

    /* @page { size: landscape; } */
}

.ql-fbShare svg,
.ql-twitterShare svg,
.ql-fb-share svg {
    width: 20px !important;
    height: 20px !important;
}
.notepad .form-group {
    margin-bottom: 0px !important;
}

.page-break {
    page-break-after: always;
}

.files {
    width: 100%;
    height: 244px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0);
    background: white;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
.fileActions {
    top: 8px;
    right: 8px;
    position: absolute;
}
.fileDisplay {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-align: center;
    align-items: center;
}

.fileDisplay canvas {
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 1;
}
.fileDisplay::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
    opacity: 0.4;
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    z-index: 0;
}
.fileName {
    position: absolute;
    height: 32px;
    width: 100%;
    bottom: 0;
    left: 0;
}
.fileName div {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 8px;
    border: 1px solid rgba(0, 0, 0, 0);
    z-index: 99;
    text-align: center;
}

.h-maxcontent {
    height: max-content !important;
}
.font-9 {
    font-size: 9px !important;
}
.font-12 {
    font-size: 12px !important;
}
.signature-img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 100px;
    width: 100px;
}

.bg-gray {
    background-color: #ccc !important;
    border-color: transparent !important;
}
.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}
.skeleton-text {
    width: 75%;
    height: 0.7rem;
    border-radius: 0.25rem;
    margin: 0 auto;
}
#header-logo {
    width: 350px;
    height: 70px;
}
#homeContent .tab-contents,
#suitesContent .tab-contents {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
#suitesContent .pages-menu .w-100 {
    padding: 5.5% 0%;
}
#homeContent .pages-menu .w-100 {
    padding: 4.5% 0%;
}

header .row {
    height: -webkit-fill-available;
}
.header .row div {
    height: fit-content;
}

@media (min-width: 769px) {
    .homepage-icon {
        width: 48px;
        height: 48px;
    }
    #homeContent .pages-menu .w-100 h4 {
        font-size: 1.5rem !important;
    }
}

#accordion {
    margin-bottom: 1rem;
}

.launch-button {
    padding: 0.2rem !important;
}

.ql-toolbar.ql-snow {
    background-color: #ccc;
}

.iframe-border {
    border: 2px groove #ccc;
}

.heading-size {
    font-size: 20px;
}
.tomorrow a {
    display: none;
}

.output-box {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 10px 0px 20px;
}

.output-box::-webkit-scrollbar {
    width: 10px;
}

/* Handle */
.output-box::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 5px;
}
.outputBox {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 5px 0px 0px;
}

.outputBox::-webkit-scrollbar {
    width: 5px;
}

.outputBox::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
}

.bg-light-warning {
    background-color: #facf9a;
}

@media (max-width: 768px) {
    #AccordionRightToolbar button svg {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
    }
}
/* Media query for screens smaller than 768px */
@media (max-width: 768px) and (orientation: portrait) {
    .input-btn-group {
        display: flex;
        width: 100%;
        border-top: 1px solid #d3d8de;
    }
    .input-group-append .input-btn {
        width: 100%;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.outputBtn:focus {
    box-shadow: none !important;
}
.output-toolbar {
    background-color: lightgray !important;
    padding: 10px !important;
}
.output-entry {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
textarea.no-resize {
    resize: none !important;
}

.input-group-border {
    border: 1px solid #d3d8de;
    border-radius: 5px;
}

button:focus {
    box-shadow: none !important;
}
.epr-category-nav {
    display: none !important;
}
.monthTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* this style is used to modify the number field to remove the number-rocker-buttons */
/* start  */
.no-spin-buttons::-webkit-inner-spin-button,
.no-spin-buttons::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spin-buttons {
    -moz-appearance: textfield;
}
/* end  */
.overflow-auto {
    overflow: auto;
}
.react-datepicker-popper,
.react-international-phone-country-selector-dropdown {
    z-index: 9999 !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: gray !important;
    top: 12px !important;
}
.react-datepicker__triangle {
    display: none !important;
}
.checkbox-column {
    column-count: 3;
}
input[type='checkbox'] {
    /* position: relative; */
    border: 2px solid #000;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: 0.5;
    vertical-align: middle;
}

input[type='checkbox']:hover {
    opacity: 1;
}

input[type='checkbox']:checked {
    background-color: #000;
    opacity: 1;
}

input[type='checkbox']:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 45%;
    width: 4px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
}
.form-check label {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    left: 30px;
    padding-right: 10px;
}
.react-international-phone-input-container {
    width: calc(100% - 126px);
}

/* Communicator dropdown content area hover hand icon issue  */
.clickable-container,
.leftHeaderSelector {
    cursor: pointer;
}

.custom-select {
    background: #fff
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23808080' d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E")
        right 0.75rem center / 18px 20px no-repeat !important;
}
.operationSelect {
    font-size: 1.5rem;
}

.footer-icon {
    width: 24px;
    height: 24px;
}

.list-group-item {
    padding: 0.5rem 1.25rem !important;
    background-color: transparent !important;
    border: 0px !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: #f7971c !important;
}

.plan_period {
    font-size: 14px;
}

/* Styles when user is not authenticated */
.orange-icon,
.orange-text {
    color: #f7971c;
}
#dropdown-items-anchor-color {
    color: black !important;
}
.colored-image {
    filter: brightness(0) saturate(100%) invert(23%) sepia(51%) saturate(10%)
        hue-rotate(326deg) brightness(88%) contrast(87%);
}
.text-black,
.ql-snow .ql-picker,
.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:visited,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active {
    color: black !important;
}
.table-striped-custom tbody tr:nth-of-type(odd) {
    background-color: #fff; /* Light gray color for odd rows */
}

.table-striped-custom tbody tr:nth-of-type(even) {
    background-color: #f8f9fa; /* White color for even rows */
}
.ql-snow .ql-stroke {
    stroke: black !important;
}
.ql-snow .ql-fill {
    fill: black !important;
}
.ql-snow .ql-transparent {
    opacity: 1 !important;
}
#profileTabPage .nav-tabs .nav-item.show .nav-link,
#profileTabPage .nav-tabs .nav-link.active:after {
    z-index: 9999 !important;
}
.react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 50px !important;
}
.dropdown-item:active {
    background-color: #e9ecef !important;
}
.ql-editor ul,
.ql-editor ol {
    margin-left: 25px !important;
}
@media (min-width: 768px) {
    .mobile-only-items {
        display: none;
    }
}

#dropdownFormat .dropdown-toggle::after,
.action-dropdown .dropdown-toggle::after {
    display: none !important;
}
.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-item:hover {
    color: black !important;
}

.dropdown-menu {
    z-index: 9999 !important;
}
/* not-found page css */
#not-found-page {
    position: relative;
    height: 100vh;
}
#not-found-page .not-found-page-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#not-found-page .not-found-404-text {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0 auto 80px;
}
#not-found-page .not-found-404-text > div:first-child {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffa200;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 5px dashed #000;
    border-radius: 5px;
}
#not-found-page .not-found-404-text > div:first-child:before {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    top: -5px;
    -webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1) inset;
    border-radius: 5px;
}
#not-found-page .not-found-404-text h1 {
    font-family: cabin, sans-serif;
    color: #000;
    font-weight: 700;
    margin: 0;
    font-size: 90px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    text-align: center;
    height: 40px;
    line-height: 40px;
}
#not-found-page h2 {
    font-family: cabin, sans-serif;
    font-size: 33px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 7px;
}
#not-found-page p {
    font-family: cabin, sans-serif;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    text-align: center;
    margin-top: 2rem;
}
#not-found-page button {
    border-radius: 50px;
}
.not-found-page-content {
    max-width: 500px;
    width: 100%;
    text-align: center;
    line-height: 1.4;
}
/* Increase the size of all toolbar icons */
.ql-toolbar svg {
    width: 22px !important;
    height: 22px !important;
}

.ql-toolbar button,
.ql-snow .ql-color-picker {
    margin-right: 12px !important;
}

.make-links-unclickable {
    pointer-events: none;
    cursor: default;
}
.cle-cruise-table tbody tr td,
.cle-cruise-table tbody tr th {
    border: 0px;
    padding: 0px 0.75rem;
}
.cle-cruise-table tbody tr td {
    padding-right: 0px !important;
}
.cle-cruise-table tbody tr th {
    padding-left: 0px !important;
}
.cle-cruise-table tbody tr th {
    white-space: nowrap;
}
@media (min-width: 320px) and (max-width: 768px) {
    .table {
        width: 100% !important;
    }
}
.spreadsheet-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* height: 100%; Adjust as needed */
}

.spreadsheet-image-container img {
    max-width: 100%; /* Adjust to fit within container */
    max-height: 100%; /* Adjust to fit within container */
}
.privacy-list {
    padding-left: 2.5rem !important;
}

.text-area-height {
    min-height: 600px;
}

@media (min-width: 320px) and (max-width: 768px) {
    .text-area-height {
        min-height: 1170px !important;
    }
}
