.addBtn,
.addBtn:hover {
    background-color: black;
    color: white;
}
/* .closeBtn,
.closeBtn:hover {
    background-color: #dc3545;
    color: white;
}
.pasteBtn,
.pasteBtn:hover {
    background-color: #007bff;
    color: white;
}
.undoBtn,
.undoBtn:hover {
    background-color: #f7971c;
    color: white;
}
.callBtn,
.callBtn:hover {
    background-color: #6dd627;
    color: white;
}
.launchBtn,
.launchBtn:hover {
    background-color: #34becb;
    color: white;
}
.emailBtn,
.emailBtn:hover {
    background-color: #2f7db5;
    color: white;
} */
/* .pdfBtn,
.pdfBtn:hover {
    background-color: #343a40;
    color: white;
}*/
.docBtn,
.docBtn:hover {
    background-color: #586575;
    color: white;
}
/* .mapBtn,
.mapBtn:hover {
    background-color: #ff6400;
    color: white;
}
.editBtn,
.editBtn:hover {
    background-color: #17a2b8;
    color: white;
}
.submitBtn,
.submitBtn:hover {
    background-color: #28a745;
    color: white;
}
.copyBtn,
.copyBtn:hover {
    background-color: #2b6969;
    color: white;
}
.imageBtn,
.imageBtn:hover {
    background-color: #e28069;
    color: white;
} */
